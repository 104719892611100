import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../UserPool';
import { TextField, Button, Container, Box, Typography, Alert, InputAdornment } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import RegistrationForm from '../components/RegistrationForm';

function Login({ onLogin }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Gestion du mode "mot de passe oublié"

  const navigate = useNavigate();

  // Fonction de validation du formulaire
  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expression régulière pour valider l'email
    if (!emailRegex.test(email)) {
      setError(t('login.invalidEmail'));
      return false;
    }

    if (password.length < 8) {  // Vérification de la longueur du mot de passe
      setError(t('login.passwordTooShort'));
      return false;
    }

    return true;  // Si toutes les vérifications passent, retourne true
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const user = new CognitoUser({ Username: email, Pool: UserPool });
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        document.cookie = `jwt=${idToken}; path=/; SameSite=Strict; Secure`;
        document.cookie = `refreshToken=${refreshToken}; path=/; SameSite=Strict; Secure`;

        onLogin();
        navigate('/dashboard');
      },
      onFailure: (err) => setError(err.message || JSON.stringify(err)),
    });
  };

  const toggleRegister = () => {
    setIsRegistering(!isRegistering);
    setIsForgotPassword(false); // Réinitialise l'état "mot de passe oublié" lorsqu'on passe à l'inscription
    setError('');
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setError('');
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          padding: 4,
          boxShadow: 3,
          mt: 4,
          width: '100%',
        }}
      >
        <Typography variant="h4" sx={{ mb: 3 }}>
          {isRegistering ? t('login.register') : isForgotPassword ? t('login.forgotPassword') : t('login.login')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {error && <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>{error}</Alert>}

          {isForgotPassword ? (
            <ForgotPasswordForm
              setIsForgotPassword={setIsForgotPassword}
              setError={setError}
            />
          ) : isRegistering ? (
            <RegistrationForm
              isRegistering={isRegistering}
              setIsRegistering={setIsRegistering}
              setError={setError}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
            />
          ) : (
            <Box component="form" onSubmit={handleSignIn} sx={{ mt: 1, width: '90%' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('login.email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                slotProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                  },
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={t('login.password')}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                slotProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '16px',
                  },
                }}
              />
              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2, borderRadius: '16px' }}>
                {t('login.login')}
              </Button>
              <Button variant="text" onClick={handleForgotPassword} sx={{ mt: 2, color: 'primary.main' }}>
                {t('login.forgotPassword')}
              </Button>
            </Box>
          )}
          <Button variant="text" onClick={toggleRegister} sx={{ mt: 2, color: 'primary.main' }}>
            {isRegistering ? t('login.alreadyHaveAccount') : t('login.dontHaveAccount')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
