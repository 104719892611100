import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import {
    IconButton,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material';


import { useTranslation } from 'react-i18next';

import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut, Replay } from '@mui/icons-material';


const VoltageMeasurementsGraph = ({ voltageMeasurements, filteredVoltageMeasurements, rPeaks, filteredRPeaks, qrsComplexes, pPeaks, tPeaks, samplingFrequency }) => {
    const curveCanvasRef = useRef(null);
    const gridCanvasRef = useRef(null); // Canvas pour le quadrillage papier millimétré
    const { t } = useTranslation();

    // État pour contrôler l'affichage de la version filtrée
    const [showFilteredData, setShowFilteredData] = useState(false);
    const [showRPeaks, setShowRpeaks] = useState(false);
    const [showQRS, setShowQRS] = useState(false);
    const [showPWave, setShowPWave] = useState(false);
    const [showTWave, setShowTWave] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [panOffset, setPanOffset] = useState(0);

    // Préparation des données
    const dataPoints = showFilteredData
        ? filteredVoltageMeasurements.map((item) => item.voltage)
        : voltageMeasurements.map((item) => item.voltage);

    const rPeakDataPoints = showFilteredData ? filteredRPeaks : rPeaks;

    // Définir highlightedSegments en utilisant useMemo
    const highlightedSegments = useMemo(() => {
        const segments = [];

        if (showQRS && qrsComplexes) {
            qrsComplexes.forEach((segment, index) => {
                if (segment.q_onset !== undefined && segment.s_offset !== undefined) {
                    const rangeStart = segment.q_onset / samplingFrequency;
                    const rangeEnd = segment.s_offset / samplingFrequency;

                    segments.push({
                        range: [rangeStart, rangeEnd],
                        backgroundColor: 'rgba(109, 157, 230, 0.8)', // Couleur pour QRS
                    });
                }
            });
        }

        if (showPWave && pPeaks) {
            pPeaks.forEach((segment) => {
                if (segment.onset !== undefined && segment.offset !== undefined) {
                    segments.push({
                        range: [segment.onset / samplingFrequency, segment.offset / samplingFrequency],
                        backgroundColor: 'rgba(255, 109, 120, 0.8)',
                    });
                }
            });
        }

        if (showTWave && tPeaks) {
            tPeaks.forEach((segment) => {
                if (segment.onset !== undefined && segment.offset !== undefined) {
                    segments.push({
                        range: [segment.onset / samplingFrequency, segment.offset / samplingFrequency],
                        backgroundColor: 'rgba(110, 225, 165, 0.8)', // Couleur pour T
                    });
                }
            });
        }

        return segments;
    }, [showQRS, qrsComplexes, showPWave, pPeaks, showTWave, tPeaks, samplingFrequency]);

    const quicklookCanvasRef = useRef(null);


    const drawQuicklook = useCallback((ctx, width, height) => {
        // Nettoyer le canvas
        ctx.clearRect(0, 0, width, height);

        // Dessiner l'aperçu de la courbe complète
        const totalDataPoints = dataPoints.length;
        const xScale = width / totalDataPoints; // Échelle horizontale sur la largeur du Quicklook
        const voltageMax = Math.max(...dataPoints);
        const voltageMin = Math.min(...dataPoints);
        const yScale = height / ((voltageMax - voltageMin) * 100000 / 10); // Conversion de V à mV
        const yOffset = height * 0.5; // Positionner le 0V au milieu

        ctx.strokeStyle = 'gray';
        ctx.lineWidth = 1;
        ctx.beginPath();

        dataPoints.forEach((point, index) => {
            const x = index * xScale;
            const y = yOffset - ((point * 1000) * yScale); // Conversion de V à mV

            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.stroke();

        // Durée totale des données en secondes
        const totalDuration = totalDataPoints / samplingFrequency;

        // Durée visible dans la courbe principale (5 secondes ajustée par le niveau de zoom)
        const visibleDuration = 5 / zoomLevel; // Durée visible (en secondes) ajustée par le zoom

        // Temps de début de la portion visible en secondes (en fonction de panOffset)
        const visibleStartTime = panOffset / samplingFrequency; // Position de départ de la portion visible (en secondes)

        // Calcul de la position et de la largeur du cadre visible dans le Quicklook
        const visibleStart = (visibleStartTime / totalDuration) * width; // Position de départ sur le canvas du Quicklook
        const visibleWidth = (visibleDuration * samplingFrequency / totalDataPoints) * width; // Largeur sur le canvas du Quicklook

        // Assurer que visibleStart et visibleWidth sont dans les limites du canvas
        const boundedVisibleStart = Math.max(0, Math.min(visibleStart, width));
        const boundedVisibleWidth = Math.max(0, Math.min(visibleWidth, width - boundedVisibleStart));

        // Dessiner le cadre bleu pour indiquer la portion visible
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(boundedVisibleStart, 0, boundedVisibleWidth, height);
    }, [dataPoints, samplingFrequency, zoomLevel, panOffset]);


    const drawGrid = useCallback((ctx, width, height, zoomLevel) => {
        // Paramètres de la grille
        const mmPerSecond = 25; // 25 mm par seconde
        const mmPerMV = 10; // 10 mm par mV (standard pour l'amplitude)

        // Grille horizontale (temps)
        const secondsPerMillimeter = 1 / mmPerSecond; // Chaque millimètre représente 0.04s
        const pixelsPerMillimeterX = (samplingFrequency * secondsPerMillimeter * width) / (samplingFrequency * 5);
        const majorGridSizeX = 5 * pixelsPerMillimeterX * zoomLevel; // 5 mm (chaque grand carré représente 0.2s)
        const minorGridSizeX = pixelsPerMillimeterX * zoomLevel;     // 1 mm (0.04s)

        // Grille verticale (amplitude) - Fixer à 10 mm par mV pour une plage de -2 mV à 2 mV
        const yMin = -2; // -2 mV
        const yMax = 2;  // 2 mV
        const totalMillimetersY = (yMax - yMin) * mmPerMV; // Total de millimètres nécessaires en hauteur
        const pixelsPerMillimeterY = height / totalMillimetersY;

        // Espacement des lignes principales et secondaires sur l'axe Y
        const majorGridSizeY = 10 * pixelsPerMillimeterY; // Grands carrés : 10 mm pour 1 mV
        const minorGridSizeY = pixelsPerMillimeterY;      // Petits carrés : 1 mm pour 0.1 mV

        // Dessiner les lignes du quadrillage
        ctx.clearRect(0, 0, width, height);

        // Dessiner les grandes lignes du quadrillage (horizontal et vertical)
        ctx.strokeStyle = 'rgba(255, 165, 0, 0.8)';  // Orange pour les grands carrés (lignes majeures)
        ctx.lineWidth = 1;
        for (let x = 0; x <= width; x += majorGridSizeX) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.stroke();
        }
        for (let y = 0; y <= height; y += majorGridSizeY) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            ctx.stroke();
        }

        // Dessiner les petites lignes du quadrillage (horizontal et vertical)
        ctx.strokeStyle = 'rgba(255, 182, 107, 0.5)';  // Orange plus clair pour les petits carrés (lignes mineures)
        ctx.lineWidth = 0.5;
        for (let x = 0, countX = 0; x <= width; x += minorGridSizeX, countX++) {
            ctx.strokeStyle = (countX % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
            ctx.lineWidth = (countX % 5 === 0) ? 1 : 0.5;
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.stroke();
        }
        for (let y = 0, countY = 0; y <= height; y += minorGridSizeY, countY++) {
            ctx.strokeStyle = (countY % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
            ctx.lineWidth = (countY % 5 === 0) ? 1 : 0.5;
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            ctx.stroke();
        }

        // Ajouter les labels de l'axe Y (en mV) pour une plage de -2 mV à 2 mV
        ctx.fillStyle = 'black';
        ctx.font = '12px Arial';
        for (let y = 0; y <= height; y += majorGridSizeY) {
            const voltageLabel = (yMax - (y / height) * (yMax - yMin)).toFixed(1);
            ctx.fillText(`${voltageLabel} mV`, 2, y - 2);
        }
    }, [samplingFrequency]);



    const drawCurve = useCallback((ctx, width, height, zoomLevel) => {
        ctx.clearRect(0, 0, width, height);
    
        // Décalage horizontal pour éviter la superposition sur les labels Y
        const xOffset = 50;
    
        // Durée visible de la courbe (5 secondes par défaut, ajustée par le niveau de zoom)
        const visibleDuration = 5 / zoomLevel; // En secondes
    
        // Calculer le nombre de points visibles
        const totalPointsVisible = Math.floor(visibleDuration * samplingFrequency);
    
        // Échelle horizontale pour ajuster la largeur totale du graphique
        const xScale = (width - xOffset) / totalPointsVisible;
    
        // Fixer les limites pour l'axe Y (de -2 mV à 2 mV)
        const yMin = -2;  // -2 mV
        const yMax = 2;   // 2 mV
    
        // Échelle verticale ajustée pour que l'axe Y aille de yMin à yMax
        const yScale = height / (yMax - yMin);
    
        // Positionner le 0V au milieu
        const yOffset = height * (yMax / (yMax - yMin));
    
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        ctx.beginPath();
    
        // Calculer l'indice de départ et de fin en fonction du `panOffset`
        const startIndex = Math.max(0, Math.floor(panOffset));
        const endIndex = Math.min(dataPoints.length, startIndex + totalPointsVisible);
    
        // Dessiner la courbe ECG
        dataPoints.slice(startIndex, endIndex).forEach((point, index) => {
            // Prendre en compte l'offset de panoramique et le décalage fixe (xOffset)
            const x = xOffset + ((index + startIndex - panOffset) * xScale);
            const y = yOffset - (point * 1000 * yScale); // Conversion de V à mV et ajustement avec yOffset
    
            if (index === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        });
        ctx.stroke();
    
        // Dessiner les points R-Peaks si activé
        if (showRPeaks) {
            ctx.fillStyle = 'red';
            rPeakDataPoints.forEach((peak) => {
                const time = peak.time; // Convertir le temps en secondes
    
                // Calcul de la position en tenant compte du `panOffset` et de la durée visible
                if (time < panOffset / samplingFrequency || time > (panOffset / samplingFrequency + visibleDuration)) return;
    
                // Convertir le temps en position x
                const adjustedTime = time - panOffset / samplingFrequency; // Ajuster le temps en fonction du `panOffset`
                const x = xOffset + adjustedTime * samplingFrequency * xScale; // Calculer la position x sur le canevas
    
                // Calculer la position y (valeur du voltage)
                const y = yOffset - (peak.voltage * 1000 * yScale); // Conversion de V à mV et ajustement avec yOffset
    
                // Dessiner le point R-peak
                ctx.beginPath();
                ctx.arc(x, y, 4, 0, 2 * Math.PI);
                ctx.fill();
            });
        }
    
        // Surligner les segments QRS, P, T si activés
        highlightedSegments.forEach((segment) => {
            const xStart = xOffset + ((segment.range[0] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
            const xEnd = xOffset + ((segment.range[1] - panOffset / samplingFrequency) * samplingFrequency) * xScale;
            ctx.fillStyle = segment.backgroundColor;
            ctx.fillRect(xStart, 0, xEnd - xStart, height);
        });
    
        // Dessiner les labels de l'axe X (temps en secondes)
        ctx.fillStyle = 'black';
        ctx.font = '12px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
    
        for (let i = 0; i <= visibleDuration * 5; i++) { // Multiplié par 5 car 5 x 0.2s = 1s
            const time = (panOffset / samplingFrequency) + i * 0.2; // Calculer le temps en fonction de 0.2s d'intervalle
            const xLabel = xOffset + i * 0.2 * samplingFrequency * xScale;
        
            if (xLabel >= xOffset && xLabel <= width) {
                ctx.save(); // Sauvegarder l'état du contexte avant la rotation
                ctx.translate(xLabel, height - 20); // Déplacer le point d'origine au niveau où le texte sera dessiné
                ctx.rotate(-Math.PI / 2); // Faire une rotation de 90° dans le sens antihoraire
                ctx.fillText(`${time.toFixed(1)} s`, 0, 0); // Dessiner le texte à l'origine de la nouvelle position
                ctx.restore(); // Restaurer l'état initial du contexte après avoir dessiné le texte
            }
        }
    }, [samplingFrequency, dataPoints, panOffset, showRPeaks, rPeakDataPoints, highlightedSegments]);
    


    useEffect(() => {
        // Dessiner le quadrillage lors du montage initial ou du changement de zoom
        const gridCanvas = gridCanvasRef.current;
        const gridCtx = gridCanvas.getContext('2d');
        drawGrid(gridCtx, gridCanvas.width, gridCanvas.height, zoomLevel);
    }, [drawGrid, zoomLevel]); // La grille est redessinée uniquement quand `zoomLevel` change


    useEffect(() => {
        // Dessiner la courbe
        const curveCanvas = curveCanvasRef.current;
        const curveCtx = curveCanvas.getContext('2d');
        drawCurve(curveCtx, curveCanvas.width, curveCanvas.height, zoomLevel);

        // Dessiner l'aperçu (Quicklook)
        const quicklookCanvas = quicklookCanvasRef.current;
        const quicklookCtx = quicklookCanvas.getContext('2d');
        drawQuicklook(quicklookCtx, quicklookCanvas.width, quicklookCanvas.height);
    }, [drawCurve, drawQuicklook, samplingFrequency, dataPoints, zoomLevel, panOffset]);

    const handlePanLeft = () => {
        setPanOffset((prev) => Math.max(0, prev - 200));
    };

    const handlePanRight = () => {
        setPanOffset((prev) => prev + 200);
    };

    const handleZoomIn = () => {
        setZoomLevel((prev) => prev * 1.2);
    };

    const handleZoomOut = () => {
        setZoomLevel((prev) => prev / 1.2);
    };

    const handleResetZoom = () => {
        setZoomLevel(1);
        setPanOffset(0);
    };


    const handleMouseDrag = useCallback((event) => {
        if (event.buttons !== 1) return;
        setPanOffset((prev) => prev - 4 * event.movementX / zoomLevel);
    }, [zoomLevel]);

    useEffect(() => {
        const curveCanvas = curveCanvasRef.current;
        curveCanvas.addEventListener('mousemove', handleMouseDrag);

        return () => {
            curveCanvas.removeEventListener('mousemove', handleMouseDrag);
        };
    }, [zoomLevel, handleMouseDrag]);

    return (
        <Box display="flex" width="100%" height="500px">
            {/* Graphique principal sur 80% de la largeur */}
            <Box
                width="80%"
                position="relative"
                display="flex"
                alignItems="center"
                height="400px"
                marginTop="20px" // Décalage pour descendre l'ensemble un peu vers le bas
            >
                {/* Flèche gauche pour le panoramique */}
                <IconButton
                    onClick={handlePanLeft}
                    style={{
                        position: 'absolute',
                        left: 10,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10
                    }}
                >
                    <ChevronLeft />
                </IconButton>

                {/* Conteneur des Canvas */}
                <Box
                    width="calc(100% - 100px)"
                    position="relative"
                    margin="0 50px"
                    display="flex"
                    justifyContent="center"
                    height="400px"
                >
                    {/* Canvas pour le quadrillage papier millimétré */}
                    <canvas
                        ref={gridCanvasRef}
                        width={800}
                        height={400}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 1
                        }}
                    />
                    {/* Canvas pour la courbe ECG */}
                    <canvas
                        ref={curveCanvasRef}
                        width={800}
                        height={400}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 2
                        }}
                    />
                </Box>

                {/* Flèche droite pour le panoramique */}
                <IconButton
                    onClick={handlePanRight}
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10
                    }}
                >
                    <ChevronRight />
                </IconButton>
            </Box>


            {/* Quicklook et case à cocher sur la partie droite */}
            <Box width="20%" display="flex" flexDirection="column" alignItems="left">
                {/* Quicklook */}
                <canvas
                    ref={quicklookCanvasRef}
                    width={200}
                    height={100}
                    style={{
                        border: '1px solid #ccc',
                        marginBottom: '16px',
                    }}
                />

                {/* Case à cocher pour afficher la version filtrée */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showFilteredData}
                            onChange={(e) => setShowFilteredData(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={t('ecg.display_filtered_version')}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showRPeaks}
                            onChange={(e) => setShowRpeaks(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={t('ecg.display_r_peaks')}
                />

                <FormControlLabel
                    control={<Checkbox checked={showQRS} onChange={(e) => setShowQRS(e.target.checked)} />}
                    label="Show QRS Complex"
                />
                <FormControlLabel
                    control={<Checkbox checked={showPWave} onChange={(e) => setShowPWave(e.target.checked)} />}
                    label="Show P Wave"
                />
                <FormControlLabel
                    control={<Checkbox checked={showTWave} onChange={(e) => setShowTWave(e.target.checked)} />}
                    label="Show T Wave"
                />

                {/* Boutons de contrôle sous la case à cocher */}
                <Box display="flex" justifyContent="center" marginTop={2}>
                    <IconButton onClick={handleZoomIn} color="primary" style={{ marginRight: 8 }}>
                        <ZoomIn />
                    </IconButton>
                    <IconButton onClick={handleZoomOut} color="secondary" style={{ marginRight: 8 }}>
                        <ZoomOut />
                    </IconButton>
                    <IconButton onClick={handleResetZoom} color="default" style={{ marginRight: 8 }}>
                        <Replay />
                    </IconButton>
                </Box>
            </Box>

        </Box>
    );
};

export default VoltageMeasurementsGraph;
