import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Collapse,
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
    Stack,
    Avatar,
} from '@mui/material';

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import CloseIcon from '@mui/icons-material/Close';


import { useTranslation } from 'react-i18next';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

import ECGAnalysisModal from './ECGAnalysisModal.js';
import VoltageMeasurementsGraph from '../components/VoltageMeasurementsGraph'

import { fetchECGDetails } from '../api/ECG.js';
import { fetchPatientECGs } from '../api/ECG.js';


const PatientRow = ({ patient, isExpanded, handleRowClick }) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0); // État pour la page actuelle
    const [rowsPerPage, setRowsPerPage] = useState(5); // État pour le nombre de lignes par page
    const [openRRModal, setOpenRRModal] = useState(false);
    const [loadingEcgId, setLoadingEcgId] = useState(null);
    const [loadingAnalysis, setLoadingAnalysis] = useState(null);
    const [ecgData, setEcgData] = useState([]);
    const [filteredEcgData, setFilteredEcgData] = useState([]);
    const [filteredRPeaks, setFilteredRPeaks] = useState([]);
    const [qrsComplexes, setqrsComplexes] = useState([]);
    const [pPeaks, setPPeaks] = useState([]);
    const [tPeaks, setTPeaks] = useState([]);
    const [samplingFrequency, setSamplingFrequency] = useState('')
    const [selectedEcg, setSelectedEcg] = useState(null);

    const [modalEcgData, setModalEcgData] = useState([]);
    const [modalRPeaks, setModalRPeaks] = useState([]);


    const [openModal, setOpenModal] = useState(false);


    const { t } = useTranslation();

    const handleFetchECGDetails = (patient, ecgId, onSuccess) => {
        fetchECGDetails(patient, ecgId)
            .then((response) => {
                setModalEcgData(response.data.voltageMeasurements);
                setFilteredEcgData(response.data.filteredVoltageMeasurements);
                setModalRPeaks(response.data.rPeaks);
                setFilteredRPeaks(response.data.filteredRPeaks);
                setqrsComplexes(response.data.qrsComplexes);
                setPPeaks(response.data.pPeaks);
                setTPeaks(response.data.tPeaks);
                setSamplingFrequency(response.data.samplingFrequency);
                setSelectedEcg(ecgId);
                onSuccess(); // Appeler la fonction de succès (ouvrir le bon modal)
                setLoadingEcgId(null);
                setLoadingAnalysis(null);
            })
            .catch(() => {
                alert('Failed to fetch ECG details.');
                setLoadingEcgId(null);
                setLoadingAnalysis(null);
            });
    };


    useEffect(() => {
        if (isExpanded && ecgData.length === 0) {
            setLoading(true);
            fetchPatientECGs(patient)
                .then((response) => {
                    setEcgData(response.data);
                    setLoading(false);
                })
                .catch(() => {
                    alert('Failed to fetch ECG data.');
                    setLoading(false);
                });
        }
    }, [isExpanded, patient, ecgData.length]);

    // Gère le changement de page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Gère le changement du nombre de lignes par page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Réinitialise la page à 0 lorsque le nombre de lignes change
    };

    const handleViewEcg = (patient, ecgId) => {
        setLoadingEcgId(ecgId);
        handleFetchECGDetails(patient, ecgId, () => {
            setOpenModal(true);
        });

    };

    const handleViewECGAnalysis = (patient, ecgId) => {
        setLoadingAnalysis(ecgId);
        handleFetchECGDetails(patient, ecgId, () => {
            setOpenRRModal(true);
        });
    };
    const handleCloseRRModal = () => {
        setOpenRRModal(false);
        setModalRPeaks([]);
        setModalEcgData([]);
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => handleRowClick(patient.patientCode)}>
                        {isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{patient.patientCode}</TableCell>
                <TableCell>{patient.accessCode}</TableCell>
                <TableCell>{patient.dateOfBirth}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom>
                                {t('ecg.list')}
                            </Typography>
                            {loading ? (
                                <Typography>{t('ecg.loading')}</Typography>
                            ) : ecgData.length > 0 ? (
                                <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('ecg.startDate')}</TableCell>
                                                <TableCell>{t('ecg.duration')}</TableCell>
                                                <TableCell>{t('ecg.device')}</TableCell>
                                                <TableCell>{t('ecg.heartRate')}</TableCell>
                                                <TableCell>{t('ecg.ECGStatus')}</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ecgData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Limite les données affichées par page
                                                .map((ecg) => {
                                                    const isLoading = loadingEcgId === ecg.id;
                                                    const isLoadingAnalysis = loadingAnalysis === ecg.id;
                                                    const formattedStartDate = ecg.startDate ? ecg.startDate.split(' +')[0] : '';
                                                    const startDateObj = new Date(ecg.startDate);
                                                    const endDateObj = new Date(ecg.endDate);
                                                    const durationMs = endDateObj.getTime() - startDateObj.getTime();
                                                    const durationSec = Math.floor(durationMs / 1000);
                                                    return (
                                                        <TableRow key={ecg.id}>
                                                            <TableCell>{formattedStartDate}</TableCell>
                                                            <TableCell>{durationSec} sec</TableCell>
                                                            <TableCell>{ecg.device}</TableCell>
                                                            <TableCell>{Math.round(ecg.heartRate)} BPM</TableCell>
                                                            <TableCell>{t(`ecg.status.${ecg.ECGStatus}`)}</TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => handleViewEcg(patient, ecg.id)}
                                                                    disabled={isLoading}
                                                                    startIcon={isLoading ? <CircularProgress size={24} /> : null}
                                                                >
                                                                    {isLoading ? t('ecg.loading') : t('ecg.view')}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => handleViewECGAnalysis(patient, ecg.id)}
                                                                    disabled={isLoadingAnalysis}
                                                                    startIcon={isLoadingAnalysis ? <CircularProgress size={24} /> : null}
                                                                >
                                                                    <SearchIcon />
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                    {/* Modal pour afficher le graphique des intervalles R-R */}
                                    <Dialog open={openRRModal} onClose={handleCloseRRModal} maxWidth="lg" fullWidth>
                                        <DialogTitle>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Avatar sx={{ bgcolor: '#007FFF', width: 40, height: 40 }}>
                                                    <MonitorHeartIcon />
                                                </Avatar>
                                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                                                    {t('ecg.analysis')}
                                                </Typography>
                                                {/* Bouton de fermeture de la modale */}
                                                <IconButton onClick={handleCloseRRModal} sx={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 8,
                                                    color: (theme) => theme.palette.grey[500],
                                                }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Stack>
                                        </DialogTitle>

                                        <DialogContent>
                                            <ECGAnalysisModal rPeaks={modalRPeaks} voltageMeasurements={modalEcgData} samplingFrequency={samplingFrequency} patient={patient} />
                                        </DialogContent>
                                    </Dialog>
                                    {/* Modal pour afficher l'ECG */}
                                    {selectedEcg && (
                                        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
                                            <DialogContent style={{ width: '100%' }}>
                                                <Typography variant="h6" gutterBottom>
                                                    {t('ecg.details')}
                                                </Typography>
                                                <VoltageMeasurementsGraph voltageMeasurements={modalEcgData} filteredVoltageMeasurements={filteredEcgData}
                                                    rPeaks={modalRPeaks} filteredRPeaks={filteredRPeaks} qrsComplexes={qrsComplexes} pPeaks={pPeaks} tPeaks={tPeaks}
                                                    samplingFrequency={samplingFrequency} />
                                            </DialogContent>
                                        </Dialog>
                                    )}
                                    {/* Composant de pagination */}
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]} // Options pour le nombre de lignes par page
                                        component="div"
                                        count={ecgData.length} // Total des lignes
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : (
                                <Typography>{t('ecg.noECG')}</Typography>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PatientRow;
