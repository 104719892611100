import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    TableSortLabel,
    TextField,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import {
    Chart as ChartJS,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
    CategoryScale,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';

import PatientRow from '../components/PatientRow';

import { fetchPatients } from '../api/Patient';

const EcgPage = () => {
    const { t } = useTranslation();

    const [patients, setPatients] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('patientCode');
    const [filterDate, setFilterDate] = useState('');

    const handleRowClick = (patientCode) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [patientCode]: !prevExpandedRows[patientCode],
        }));
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterDateChange = (event) => {
        setFilterDate(event.target.value);
    };

    useEffect(() => {
        fetchPatients()
            .then((response) => {
                setPatients(response.data);
            })
            .catch(() => {
                alert('Failed to fetch patients.');
            });
    }, []);

    const sortAndFilterPatients = (patients) => {
        const filteredPatients = patients.filter((patient) =>
            filterDate ? patient.dateOfBirth.startsWith(filterDate) : true
        );

        return filteredPatients.sort((a, b) => {
            if (order === 'asc') return a[orderBy] < b[orderBy] ? -1 : 1;
            return a[orderBy] > b[orderBy] ? -1 : 1;
        });
    };

    return (
        <Container maxWidth="lg" style={{ marginTop: '40px' }}>
            <TextField
    label={t('ecg.filterByDoB')}
    type="date"
    value={filterDate}
    onChange={handleFilterDateChange}
    focused
    style={{ marginBottom: '16px', width: '100%' }}
/>

            <TableContainer component={Paper} elevation={3} style={{ borderRadius: '8px', overflow: 'hidden' }}>
                <Table>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                            <TableCell />
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'patientCode'}
                                    direction={orderBy === 'patientCode' ? order : 'asc'}
                                    onClick={() => handleSort('patientCode')}
                                >
                                    {t('ecg.patientCode')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'accessCode'}
                                    direction={orderBy === 'accessCode' ? order : 'asc'}
                                    onClick={() => handleSort('accessCode')}
                                >
                                    {t('ecg.accessCode')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'dateOfBirth'}
                                    direction={orderBy === 'dateOfBirth' ? order : 'asc'}
                                    onClick={() => handleSort('dateOfBirth')}
                                >
                                    {t('ecg.dob')}
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortAndFilterPatients(patients).map((patient) => (
                            <PatientRow
                                key={patient.patientCode}
                                patient={patient}
                                isExpanded={expandedRows[patient.patientCode]}
                                handleRowClick={handleRowClick}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

// Enregistrement des composants et plugins Chart.js
ChartJS.register(
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
    CategoryScale,
    zoomPlugin,
    annotationPlugin
);

export default EcgPage;
