import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Container,

} from '@mui/material';

import { useMemo } from 'react';

import { fetchPatients } from '../api/Patient';
import PatientForm from '../components/PatientForm';


const useSortedAndFilteredPatients = (patients, order, orderBy, filterDate) => {
  return useMemo(() => {
    const sortedPatients = [...patients].sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    });

    if (!filterDate) return sortedPatients;

    return sortedPatients.filter((patient) =>
      patient.dateOfBirth.startsWith(filterDate)
    );
  }, [patients, order, orderBy, filterDate]);
};


const PatientsList = ({ patients, setFormData, setShowForm }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('patientCode');
  const [filterDate, setFilterDate] = useState('');

  const sortedAndFilteredPatients = useSortedAndFilteredPatients(patients, order, orderBy, filterDate);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  return (
    <Paper style={{ padding: '16px', marginBottom: '24px' }}>
      <TextField
        label={t('patients.filterByDateOfBirth')}
        type="date"
        value={filterDate}
        onChange={handleFilterDateChange}
        slotProps={{
          inputLabel: { shrink: true },
      }}
        style={{ marginBottom: '16px', width: '100%' }}
      />

      <PatientsTable
        patients={sortedAndFilteredPatients}
        order={order}
        orderBy={orderBy}
        handleSort={handleSort}
        setFormData={setFormData}
        setShowForm={setShowForm}
        t={t}
      />
    </Paper>
  );
};

const PatientsTable = ({ patients, order, orderBy, handleSort, setFormData, setShowForm, t }) => (
  <Container maxWidth="lg" style={{ padding: '16px', marginBottom: '24px' }}>

    <Table>
      <TableHead>
        <TableRow style={{ backgroundColor: '#f5f5f5' }}>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'patientCode'}
              direction={orderBy === 'patientCode' ? order : 'asc'}
              onClick={() => handleSort('patientCode')}
            >
              {t('patients.patientCode')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'accessCode'}
              direction={orderBy === 'accessCode' ? order : 'asc'}
              onClick={() => handleSort('accessCode')}
            >
              {t('patients.accessCode')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={orderBy === 'dateOfBirth'}
              direction={orderBy === 'dateOfBirth' ? order : 'asc'}
              onClick={() => handleSort('dateOfBirth')}
            >
              {t('patients.dateOfBirth')}
            </TableSortLabel>
          </TableCell>
          <TableCell>{t('patients.viewMore')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {patients.map((patient, index) => (
          <TableRow key={index}>
            <TableCell>{patient.patientCode}</TableCell>
            <TableCell>{patient.accessCode}</TableCell>
            <TableCell>{patient.dateOfBirth}</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setFormData(patient);
                  setShowForm(true);
                }}
              >
                {t('patients.viewDetails')}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Container>

);


function Patient() {
  const { t } = useTranslation();

  const initialFormData = {
    gender: '',
    dateOfBirth: '',
    symptoms: [],
    prescriptionDrugs: {
    },
    archivedDrugs: {
    },
    arrythmias: 'no',
    arrhythmiasPersistence: '',
    crisisDuration: '',
    crisisDurationUnit: 'min',
    triggers: [],
    maneuvers: [],
    maneuverTiming: '',
    exams: {
      echocardiography: false,
      electrophysiology: false,
      stressTest: false,
      otherExam: false,
      echoConclusion: '',
      electrophysiologyConclusion: '',
      stressTestConclusion: '',
      otherExamConclusion: '',
    },
  };


  const resetForm = () => {
    setFormData(initialFormData);
  };

  const [patients, setPatients] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(initialFormData);


  useEffect(() => {
    fetchPatients()
      .then((response) => {
        setPatients(response.data);
      })
      .catch(() => {
        alert('Failed to fetch patients. Please try again later.');
      });
  }, []);


  return (
    <Container maxWidth="lg" style={{ padding: '16px' }}>

      <div className="patient-container" style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%' }}>
          {/* Bouton pour afficher/masquer le formulaire */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (showForm) {
                resetForm();
              }
              setShowForm(!showForm);
            }}
            style={{ marginBottom: '20px' }}
          >
            {showForm ? t('patients.cancel') : t('patients.addNewPatient')}
          </Button>

          {/* Formulaire de création de patient */}
          {showForm && (
            <PatientForm formData={formData} setFormData={setFormData} setShowForm={setShowForm} setPatients={setPatients} />
          )}

          {/* Liste des patients */}
          <PatientsList patients={patients} setFormData={setFormData} setShowForm={setShowForm} />
        </div>
      </div >
    </Container>
  );
}

export default Patient;

